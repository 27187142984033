<template>
  <div
    class="container p0"
    v-title
    :data-title="$t('i18n.joyIOT')"
    id="notFound"
  >
    <div class="form">
      <h1>404</h1>
      <p>当前页面不存在</p>
      <el-button class="refresh" @click="$router.go(0)">刷新重试</el-button>
      <el-button class="back" @click="$router.go(-1)">返回上一级</el-button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  name: "NotFound",
  setup(props, { emit }) {
    emit("public_header", false);

    const state = reactive({});

    const init = () => {};

    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
#notFound {
  max-width: 100%;
  height: 100%;
  background: url("../../public/images/bj.png");
  background-size: cover;
  .form {
    letter-spacing: 2px;
    width: 300px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    position: relative;
    top: 35%;
    left: 30%;
    h1 {
      font-size: 100px;
    }
    .el-button {
      letter-spacing: 2px;
      display: block;
      margin: 20px auto 0;
      width: 160px;
      border: none;
      border-radius: 20px;
      color: #fff;
    }
    .refresh {
      background: #5093fe;
    }
    .back {
      background: #a460f1;
    }
  }
}
@media screen and (max-width: 768px) {
  #notFound {
    .form {
      left: 30px;
    }
  }
}
</style>
